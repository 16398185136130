import styled, { css } from "styled-components"

import { DeviceMonitoringStatus } from "src/components/Devices/DeviceMonitoringStatus"
import { DeviceLastUpdated } from "src/components/Homes/DeviceDetails/DeviceLastUpdated"
import {
  getDeviceHardwareType,
  getSensorValue,
  isEurekaDevice,
  isWaterLeakDetectionDevice,
} from "src/data/devices/logic/deviceLogic"
import { HardwareType, TDevice } from "src/data/devices/types/deviceTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { TextButton } from "src/ui/Button/TextButton"
import { mColors } from "src/ui/colors"
import A1Sensor from "src/ui/icons/eureka-illustration.svg"
import HumidityIcon from "src/ui/icons/humidity.svg"
import SoundIcon from "src/ui/icons/noise-normal.svg"
import P2Sensor from "src/ui/icons/p2-top.svg"
import P3Sensor from "src/ui/icons/p3-top.svg"
import TemperatureIcon from "src/ui/icons/temperature.svg"
import WLD from "src/ui/illustrations/wld-top.svg"
import LocalizedTemperature from "src/ui/LocalizedTemperature"
import { MCardInteractive } from "src/ui/MCard/MCardInteractive"
import { BodyMixin, MText, SubtitleMixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function Devices({
  devices,
  timezone,
}: {
  devices: TDevice[]
  timezone: string
}) {
  return (
    <>
      {devices.map((device) => {
        return (
          <Device key={device.device_id} data={device} timezone={timezone} />
        )
      })}
    </>
  )
}

export function Device({
  data: device,
  timezone,
}: {
  data: TDevice
  timezone: string
}) {
  const { t } = useTranslate()
  const { sound, temperature, humidity } = device.latest_sensor_values ?? {}
  const chargeStatus = device.charge_status
  const linkTo = Routes.SensorsWithDeviceId.location(device.device_id).pathname
  const isA1Device = isEurekaDevice(device)
  const isWLDDevice = isWaterLeakDetectionDevice(device)

  return (
    <MCardInteractive linkTo={linkTo} key={linkTo} disabled={isA1Device}>
      <Top $singleRow={isA1Device || isWLDDevice}>
        <DeviceName>
          <DeviceImg device={device} />

          <div>
            <DeviceTitle>{device.description}</DeviceTitle>
            <MText variant="bodyS" color="secondary">
              <DeviceLastUpdated
                online={!device.offline}
                lastHeardFromAt={device?.last_heard_from_at}
                timezone={timezone}
              />
            </MText>
          </div>
        </DeviceName>

        <DeviceMonitoringStatus device={device} />
      </Top>

      <DeviceInfo $hidden={isA1Device || isWLDDevice}>
        <SensorValue>
          <SoundIcon width={25} />
          <SensorText>
            {device.offline ? `-` : `${getSensorValue(sound)} dB`}
          </SensorText>
        </SensorValue>
        <SensorValue>
          <TemperatureIcon width={25} />
          <SensorText>
            {chargeStatus === "connected_charging" ? (
              <MText variant="bodyS" color="secondary">
                {t(langKeys.sensor_data_charging_placeholder)}
              </MText>
            ) : device.offline ? (
              `-`
            ) : (
              <LocalizedTemperature value={temperature?.value} />
            )}
          </SensorText>
        </SensorValue>
        <SensorValue>
          <HumidityIcon width={25} />
          <SensorText>
            {chargeStatus === "connected_charging" ? (
              <MText variant="bodyS" color="secondary">
                {t(langKeys.sensor_data_charging_placeholder)}
              </MText>
            ) : device.offline ? (
              `-`
            ) : (
              `${getSensorValue(humidity)} %`
            )}
          </SensorText>
        </SensorValue>
      </DeviceInfo>

      <Actions $hidden={isA1Device || isWLDDevice}>
        <TextButton>{t(langKeys.home_sensor_more_data)}</TextButton>
      </Actions>
    </MCardInteractive>
  )
}

const MonitoringDeviceTopCss = css`
  padding-bottom: ${spacing.M};
  margin-bottom: ${spacing.M};
  border-bottom: 1px solid ${mColors.divider};
`
const Top = styled.div<{ $singleRow?: boolean }>`
  ${({ $singleRow }) => ($singleRow ? "" : MonitoringDeviceTopCss)};
`

const DeviceTitle = styled.div`
  ${SubtitleMixin}
`

const DeviceInfo = styled.div<{ $hidden?: boolean }>`
  display: ${({ $hidden }) => ($hidden ? "none" : "inline-grid")};
  gap: ${spacing.M};
  grid-template-columns: repeat(4, auto);
  margin-top: ${spacing.XS};
  font-size: 0.875rem;
`

const SensorValue = styled.div`
  ${BodyMixin}
  display: grid;
  place-items: center;
  text-align: center;
`

const Actions = styled.div<{ $hidden?: boolean }>`
  display: ${({ $hidden }) => ($hidden ? "none" : "flex")};
  margin-top: ${spacing.M};
  padding-top: ${spacing.M};
  border-top: 1px solid ${mColors.divider};
`

const DeviceName = styled.div`
  display: flex;
  align-items: center;
`
const SensorText = styled.div`
  margin-top: ${spacing.XS};
  word-break: keep-all;
`

function DeviceImg({ device }: { device: TDevice }) {
  const deviceType = getDeviceHardwareType(device.hardware_version).type

  function DeviceImage() {
    switch (deviceType) {
      case HardwareType.A1:
        return <A1Sensor />
      case HardwareType.P3:
        return <P3Sensor />
      case HardwareType.WLD:
        return <WLD />
      default:
        return <P2Sensor />
    }
  }

  return (
    <DeviceImageBox>
      <DeviceImage />
    </DeviceImageBox>
  )
}

const DeviceImageBox = styled.div`
  margin-right: ${spacing.M};
  & > svg {
    width: 40px;
    height: 40px;
  }
`
